import { Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Routing } from '../../../constants/Routing';
import { environment } from '../../../../environments/environment';
import { PlatformDetectorService } from './platform-detector.service';

@Injectable({
  providedIn: 'root'
})
export class UrlService {

  constructor(@Inject(Location) private location: Location,
              private pd: PlatformDetectorService,) { }

  getCurrentUrl(): string {
    return this.location.path();
  }
  getCurrentFullUrl(): string {
    const urlPath = this.getCurrentUrl();
    if(urlPath.includes('http')) return urlPath;
    return `${environment.protocol}${environment.host}${urlPath}`;
  }

  removeQueryFromUrl(url: string) {
    return url.split('?')[0];
  }

  getFullUrl(): string {
    return `${this.getOrigin()}${this.getUrl()}`;
  }

  getUrl(): string {
    if (this.pd.isServer) {
      //TODO change this after ssr
    //  return this.request.url;
      return '';
    } else {
      return window.location.pathname + window.location.search;
    }
  }

  getOrigin(): string {
    return `${this.getProtocol()}//${this.getHost()}`;
  }

  getProtocol(): string {
    return environment.protocol.replace('//', '');
  }

  getHost(): string {
    if (this.pd.isServer) {
      //TODO change this after ssr
      //return this.request.get('host');
      return '';
    } else {
      return window.location.host;
    }
  }

  prepareRelativePath(url: string) {
    return this.removeDomainFromUrl(this.removeQueryFromUrl(url));
  }

  removeDomainFromUrl(url: string): string {
    return url.replace(/.*\/\/[^/]*/, '');
  }

  getIdFromNewsUrlString(url: string): number {
    const regex = new RegExp(Routing.ID_NEWS_TRANSLITERATION_MATCHER, 'gm');

    return Number(regex.exec(url)?.[2]);
  }

  getTagNameFromUrl(url: string): string {
    return url.replace('/', '').split('_')?.[0] || '';
  }

  getDomain() {
    return `${environment.protocol}${environment.host}`;
  }
}
