import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import {PlatformDetectorService} from "./platform-detector.service";
import { Response as ExpressResponse } from 'express';

import {UrlService} from "./url.service";
import {News} from "../models/news";
import {RESPONSE} from "../../../token";
import {FetchPriority} from "../models/fetchPriority";

@Injectable({
  providedIn: 'root'
})
export class ServerPushService {

  constructor(
    private pd: PlatformDetectorService,
    @Optional() @Inject(RESPONSE) private response: ExpressResponse,
    @Inject(LOCALE_ID) private locale: string,
    private urlService: UrlService,
  ) {}


  public pushCommonResourcesToPreload(): void {
    this.pushFontsCommon();
    this.pushMainLogo();
  }

  /**
   * Use it to server push resources
   * In fact, it adds a link to the response header with the specified resource
   * @param file should be relative file path.
   * @param type will be placed into lin header as a 'as=type'
   *
   * @param extType
   * @param crossorigin
   * @param fetchPriority
   * @see  ["Guide HTTP2 server push"](https://www.smashingmagazine.com/2017/04/guide-http2-server-push/)
   */
  public pushResource(file: string, type: string, extType: string, crossorigin: boolean = true,
                      fetchPriority = FetchPriority.AUTO) {
    if (this.pd.isServer && this.response) {
      this.response.append('Link',
        [`<${file}>; as=${type}; ${extType ? 'type=' + extType + ';' : ''} rel=preload; ${crossorigin ? 'crossorigin=anonymous;' : '' } fetchpriority=${fetchPriority};`]);
    }
  }

  public pushPhotoForNews(imgUrl: string): void {
    this.pushResource(this.urlService.removeDomainFromUrl(imgUrl), 'image', 'image/webp', false, FetchPriority.HIGH);
  }

  public pushMainLogo(): void {
    const logoUrl = '/assets/images/logo-white.svg';
    this.pushResource(this.urlService.removeDomainFromUrl(logoUrl), 'image', 'image/svg+xml', false, FetchPriority.AUTO);
  }

  public pushFont(file: string, fetchPriority: FetchPriority): void {
    if (this.pd.isServer && this.response) {
      this.response.append('Link', [`<${file}>; rel=preload; as=font; crossorigin; type=font/woff2; fetchpriority=${fetchPriority};`]);
    }
  }

  private pushFontsCommon(): void {
    this.pushFont('/assets/fonts/inter/Inter-Regular.woff2', FetchPriority.HIGH);
    this.pushFont('/assets/fonts/inter/Inter-ExtraBold.woff2', FetchPriority.HIGH);
    this.pushFont('/assets/fonts/inter/Inter-Bold.woff2', FetchPriority.HIGH);
  }
}










